// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bridge-js": () => import("./../../../src/pages/bridge.js" /* webpackChunkName: "component---src-pages-bridge-js" */),
  "component---src-pages-clear-cache-js": () => import("./../../../src/pages/clear_cache.js" /* webpackChunkName: "component---src-pages-clear-cache-js" */),
  "component---src-pages-greenwoodx-admin-2020-js": () => import("./../../../src/pages/greenwoodx_admin_2020.js" /* webpackChunkName: "component---src-pages-greenwoodx-admin-2020-js" */),
  "component---src-pages-gwx-admin-js": () => import("./../../../src/pages/gwx_admin.js" /* webpackChunkName: "component---src-pages-gwx-admin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-onboard-js": () => import("./../../../src/pages/onboard.js" /* webpackChunkName: "component---src-pages-onboard-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-view-js": () => import("./../../../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */),
  "component---src-pages-waitlist-js": () => import("./../../../src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */),
  "component---src-pages-x-js": () => import("./../../../src/pages/x.js" /* webpackChunkName: "component---src-pages-x-js" */)
}

