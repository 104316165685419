/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "typeface-nunito-sans"

import mixpanel from 'mixpanel-browser';
import React from 'react'

import { MixpanelContext } from './src/lib/tracker';

export const wrapRootElement = ({ element }) => {
  mixpanel.init('c72291ab54d531486c8a3f5b7f8f4498');
  if (process.env.NODE_ENV !== 'production') {
    mixpanel.disable();
  }

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {element}
    </MixpanelContext.Provider>
  );
};

